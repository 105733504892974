const capitlize = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};
export function sliceTypeMatches(type, sliceType) {
  // create a camel case version of the slice type

  //   console.log(type, "testing against", [
  //     sliceType,
  //     sliceType.split("_").map(capitlize).join(""),
  //     sliceType.toLowerCase(),
  //     capitlize(sliceType),
  //   ]);

  return (
    type === sliceType ||
    type === sliceType.split("_").map(capitlize).join("") ||
    type === sliceType.toLowerCase() ||
    type === capitlize(sliceType)
  );
}
